@tailwind base;
@tailwind components;
@tailwind utilities;


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

@layer components {
    .max-container {
        max-width: 1440px;
        margin: 0 auto;
    }
}

@layer utilities {
    .padding {
        @apply sm:px-16 px-8 sm:py-24 py-12;
    }

    .padding-x {
        @apply sm:px-16 px-8;
    }

    .padding-y {
        @apply sm:py-24 py-12;
    }

    .padding-l {
        @apply sm:pl-16 pl-8;
    }

    .padding-r {
        @apply sm:pr-16 pr-8;
    }

    .padding-t {
        @apply sm:pt-24 pt-12;
    }

    .padding-b {
        @apply sm:pb-24 pb-12;
    }

    .info-text {
        @apply font-montserrat text-slate-gray text-lg leading-7;
    }

    .heroText {
        @apply text-[35px] lg:text-[48px] font-extrabold text-center lg:text-left leading-tight;
    }

    .titleHeader {
        @apply text-[25px] lg:text-[36px] font-bold
    }

    .titleText {
        @apply text-[18px] lg:text-[20px] text-[#1a1a1a]/50 font-normal
    }

    .tabTitle {
        @apply text-[25px] lg:text-[35px] font-semibold bg-clip-text text-transparent bg-gradient-to-r from-gradient-variant2 to-gradient-variant1
    }

    .input {
        @apply lg:flex-1 max-lg:w-full w-[40%] text-base leading-normal pl-5 lg:p-5 outline-none;
    }
}
